@import url('https://fonts.googleapis.com/css?family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


body {
    font-family: 'Mukta', sans-serif;
    background-color: #222;
}


.back-pic {
    background-image: url('./img/kitchen2.png');
    background-repeat: no-repeat;
    background-size: 2000px;
    background-position: right;
}

.top-gap {
    height: 30px;
}

.title-box-logo {
    background-color: #FFFFFF;
}

.tagline-box {
    color: white;
    background-color: rgb(50, 50, 50, 0.9);

}

.tagline-text {
    height: 100px;
    text-align: center;
    color: #FFF;
    font-style: italic;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.title-box-logo-img {
    background-image: url('./img/ArmstrongCARlogo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    height: 100%;
    margin: 5px;
}

.footer-box-logo-img {
    background-image: url('./img/ArmstrongCARlogo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    height: 80px;
    margin-top: 30px;
}

/* .footer-samurai-img {
    background-image: url('./img/samuraiCert_128.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    height: 160px;
    margin-top: 40px;
} */

.title-box-links {
    background-color: #2E3192;
}

.title-contact-element {
    color: #fff;
    height: 67px;
}

.contact-content {
    height: 100%;
    margin-left: 5%;
}

.nav-content {
    height: 100%;
    margin-left: 100px;
}

.nav-bar {
    height: 53px;
}

.serving-banner {
    text-align: center;
    color: #FFF;
    font-weight: bold;
}

.con-form {
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.83);
}

.con-form-title {
    text-align: center;
    color: #2E3192;
    font-weight: bold;
    font-size: 30px;
    padding: 10px;
}

.form-content {
    margin-bottom: 15px;

}

.description-field {}

.office-hours-title {
    padding-top: 30px;
    height: 80px;
    text-align: center;
    color: #FFF;
    font-weight: bold;
    font-size: 45px;
}

.day-title {
    font-size: 30px;
    margin-top: 10px;
    height: 40px;
}

.office-hours-closed {
    text-align: center;
    color: #FFF;
    font-weight: bold;
    font-size: 35px;
    margin-top: 15px;
}

.office-hours-closed-red {
    color: #C1272D;
}

.call-us {
    height: 110px;
    text-align: center;
    color: #FFF;
    font-weight: bold;

}

.footer {
    background-color: #020747;
}

.footer-box {
    background-color: #fff;
    height: 100%
}

.footer-headoffice {
    text-decoration: underline;
    font-weight: bolder;
    font-size: 12px;
    margin-left: 60px;
}

.footer-text {
    font-size: 12px;
    margin-top: 5px;
}

.footer-title-text {
    text-decoration: underline;
    font-size: 14px;
    margin-top: 50px;
    margin-left: 30px;
    color: #fff;
}

.footer-info-text {
    font-size: 12px;
    margin-left: 30px;
    color: #fff;
}

.footer-copyright {
    font-size: 12px;
    margin-left: 30px;
    color: #fff;
}

.footer-copyrightback {
    background-color: #222;
}

.modal {
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid rgb(204, 204, 204);
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
}